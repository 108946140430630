@import "reset";
@import "constants";
@import "colors";
@import "fonts";
@import "mixins";

html,
body {
    height: 100%;
}

#root {
    display: flex;
    flex-direction: column;
    justify-content: stretch;
    align-items: stretch;
    align-content: stretch;
    flex-grow: 1;
    width: 100%;
    min-height: 100%;

    &:after {
        display: block;
        content: "";
    }
}

.ant-layout-header {
    height: 68px;
    line-height: 68px;
    padding: 0;
    background-color: transparent;
}

.ant-layout-content {
    min-height: 50%;
}

.ant-layout-footer {
    padding: 0;
    background-color: transparent;
}

.ant-form {
    display: contents;

    .ant-form-item {
        .ant-form-item-label {
            label {
                font-family: montserratmedium;
                font-weight: normal;
                font-size: 14px;
                color: #030000;
            }
        }
    }
}

.ant-carousel {
    display: flex;
    flex-direction: column;
    align-content: stretch;
    align-items: stretch;
}

.ant-modal-root {
    .ant-modal {
        .ant-modal-content {
            -webkit-border-radius: 6px;
            -moz-border-radius: 6px;
            border-radius: 6px;

            .ant-modal-header {
                -webkit-border-top-left-radius: 6px;
                -webkit-border-top-right-radius: 6px;
                -moz-border-radius-topleft: 6px;
                -moz-border-radius-topright: 6px;
                border-top-left-radius: 6px;
                border-top-right-radius: 6px;

                .ant-modal-title {
                    font-family: montserratbold;
                    font-weight: bold;
                    font-size: 19px;
                }
            }
            .ant-modal-body {
                .MuiTextField-root {
                    margin-bottom: 12px;

                    .MuiInputLabel-outlined {
                        font-family: montserratmedium;
                        font-size: 14px;
                    }
                }
            }
            .ant-modal-footer {
                .ant-btn {
                    -webkit-border-radius: 4px;
                    -moz-border-radius: 4px;
                    border-radius: 4px;

                    &.ant-btn-primary {
                        background-color: #003da5;
                        border-color: #003da5;
                    }
                }
            }
        }
    }
}

.MuiPickersModal-dialogRoot {
    .MuiDialogContent-root,
    .MuiPickersModal-dialog {
        .MuiPickersBasePicker-container {
            .MuiPickersToolbar-toolbar {
                background-color: #013257;
            }

            .MuiPickersBasePicker-pickerView {
                .MuiPickersDay-daySelected {
                    background-color: #013257;

                    &:hover {
                    }
                }
            }
        }
    }
    .MuiDialogActions-root {
        .MuiButton-textPrimary {
            color: #374761;
        }
    }
}

.MuiButton-root {
    & ~ .MuiButton-root {
        margin-left: 20px;
    }

    &.MuiButton-text {
        .MuiButton-label {
            text-transform: capitalize;
        }
    }

    &.MuiButton-contained {
        padding: 6px 33px;
        color: black;
        background-color: white;
    }

    &.MuiButton-containedPrimary {
        color: #ffffff;
        text-transform: capitalize;
        background-color: #003da5;
    }
}

.MuiMenu-list {
    .MuiListItem-root {
        .MuiListItemIcon-root {
            min-width: 30px;
        }
    }
}

#email-editor-dialog {
    .email-editor-dialog-appbar {
        position: relative;

        .email-editor-dialog-appbar-header {
            flex: 1;
            font-family: montserratmedium;
            font-weight: bold;
            font-size: 18px;
            color: #ffffff;
        }

        .MuiButton-root {
            &:hover {
                background-color: #2da0d9;
            }

            .MuiButton-label {
                font-family: montserratregular;
                font-size: 16px;
            }
        }
    }

    .email-editor-dialog-content {
        background-color: #f4f5f7;
        .rdw-editor-main{
            height: auto;
        }
       
        .email-editor-subject {
            margin-bottom: 5px;
        }

        .email-editor-cont {
            display: contents;

            .email-editor-wysywyg {
                background-color: #ffffff;
            }
        }
    }
}

#edit-proj-section,
#edit-schedule-section {
    .ant-space {
        display: flex;
        margin-bottom: 10px;

        .ant-space-item {
            & > label {
                font-family: montserratbold;
                font-weight: bold;
            }
        }
    }
    .add-user {
        margin-top: 10px;
        .ant-btn {
            color :#003da5;
            text-align: start;
            padding:0 ;
                > svg {
                    vertical-align: bottom;
                    padding-right: 3px;
                } 
        }
    }
    
}

#loading-dialog {
    z-index: 1201;
    color: #ffffff;
}

.ant-popconfirm {
    z-index: 1300;
}

/*1600px - 1920px*/
@include grid(xxl) {
}

/*1200px - 1599px*/
@include grid(xl) {
}

/*992px - 1199px*/
@include grid(lg) {
}

/*768px - 991px*/
@include grid(md) {
}

/*576px - 767px*/
@include grid(sm) {
}

/*<576px*/
@include grid(xs) {
}

/*1600px*/
@include layout(xxl) {
}

/*1200px*/
@include layout(xl) {
}

/*992px*/
@include layout(lg) {
}

/*768px*/
@include layout(md) {

}

/*576px*/
@include layout(sm) {
    body{
        .ant-picker-panel-container{
            left: 5%;
            .ant-picker-panels {
                display: flex;
                flex-direction: column;
            }
        }
        .ant-picker-date-panel{
            display: flex;
            flex-direction: column;
            width: 300px;
        }
        .ant-picker-panel-part {
            width: 100%;
        }
        .ant-picker-panel {
            width: 300px;
        }
        .ant-picker-range-wrapper{
            flex-direction: column;
        }
    }
}

/*480px*/
@include layout(xs) {
}

.input-fieldset {
    position: relative;
    color: #213da5;
    margin-bottom: 24px;

    .ant-input,
    .ant-select .ant-select-selector {
        border-color: #213da5 !important;
        border-radius: 5px;
        box-shadow: 0px 3px 6px #00000029;
        color: #646d82;
        font-size: 11px;
    }

    input {
        height: 34px;
    }

    textarea {
        resize: none;
        padding: 12px;
    }

    .input-label {
        position: absolute;
        top: 0;
        left: 0.5rem;
        background-color: white;
        transform: translateY(-0.625rem);
        font-size: 0.875rem;
        line-height: 1.25rem;
        padding-left: 0.5rem;
        padding-right: 0.5rem;
        font-size: 11px;
    }
}

#notistack-snackbar {
    white-space: pre-wrap;
}

.ant-form .ant-row .flex-direction-row {
    flex-direction: row;
}

.MuiButtonBase-root {
    &.MuiButton-root {
        text-transform: capitalize;
    }
}

.rmdp-panel-body {
    li {
        .b-deselect {
            color: black;
        }
    }
}

.swal-footer {
    text-align: center;
}

.swal-button {
    padding: 6px 33px;
    background-color: #003da5;

    &:not([disabled]) {
        &:hover {
            background-color: #2e3f9e;
        }
    }
}

.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu:hover::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-active::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-active::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-open::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-open::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-selected::after {
    border-bottom: none;
}