.flex-center {
    display: flex;
    justify-content: center;

    .ant-result {
        width: 30%;

        .img-150 {
            width: 150px;
            height: 150px;
        }
    }
}

#participants-schedule-booking-detail-page {
    .participants-booking-detail {
        margin: 0 auto;
        width: 550px;
        font-size: 13px;

        .form-header {
            height: 20px;
            background-color: #16216a;
            border-top-left-radius: 15px;
            border-top-right-radius: 15px;
        }
        .close-button{
            float: right;
            color: white;
            cursor: pointer;
        }

        .form-content {
            padding: 40px;
            border-bottom-left-radius: 15px;
            border-bottom-right-radius: 15px;
            background-color: white;
            box-shadow: 0px 3px 6px #00000029;

            .content-title {
                margin: 10px 0;
                font-size: 18px;
                font-weight: bolder;
                color: #16216a;
            }

            .content-flex {
                display: flex;
                justify-content: space-between;
            }

            .content-row {
                &.space {
                    margin-bottom: 10px;
                }

                .label {
                    color: #003da5;
                    font-weight: bolder;

                    svg {
                        font-size: 13px;
                    }
                }

                .value {
                    color: #646d82;
                }
            }
        }

        .form-footer {
            margin-top: 22px;
            text-align: right;

            button {
                margin-left: 15px;
            }
        }
    }
}

.confirm-content {
    .confirm-title {
        font-size: 18px;
        font-weight: bolder;
        color: #16216a;
        margin-bottom: 10px;
    }

    .confirm-question {
        font-size: 14px;

        .confirm-text {
            margin-bottom: 10px;
        }

        .confirm-text-blue {
            font-weight: bolder;
            color: #16216a;
            margin-bottom: 10px;
        }
    }

    .confirm-description {
        font-size: 13px;
        margin-bottom: 10px;
    }
}
