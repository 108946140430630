@import "../../styles/constants";
@import "../../styles/mixins";

#sidebar-section {
    display: flex;
    flex-direction: column;
    width: 125px;
    height: 100%;
    .sidebar-section-navlink {
        flex-grow: 1;

        .nav-lnk {
            display: inline-block;
            list-style: none;

            li {
                display: block;

                & > a {
                    display: block;
                    padding: 15px 11%;
                    text-align: center;
                    color: #ffffff;

                    &.active-link {
                        color: #ffffff;
                        border-right-style: solid;
                        border-right-width: 3px;
                        border-right-color: #00a3e0;
                        background-color: #16216a;
                    }

                    &:hover {
                        font-weight: bold;
                        color: #ffffff;
                        border-right-style: solid;
                        border-right-width: 3px;
                        border-right-color: #00a3e0;
                        background-color: #16216a;
                    }

                    img {
                        display: block;
                        width: 24px;
                        height: auto;
                        margin-left: auto;
                        margin-right: auto;
                        margin-bottom: 5px;
                    }

                    span {
                        display: block;
                        font-family: montserratmedium;
                        font-size: 14px;
                        line-height: 18px;
                        font-weight: normal;
                        font-stretch: normal;
                        font-style: normal;
                        clear: both;
                    }
                }
            }
        }
    }

    .sidebar-section-menu {
        display: flex;
        height: 100px;
        justify-content: center;
        align-items: center;
        background-color: #16216a;
        .MuiAvatar-root{
            width: 60px;
            height: 60px;
            .MuiSvgIcon-root {
                width: 2em;
                height: 1.2em;
            }
        }
    }
}

/*1600px*/
@include layout(xxl) {
}

/*1200px*/
@include layout(xl) {
}

/*992px*/
@include layout(lg) {
}

/*768px*/
@include layout(lg) {
    #sidebar-section {
        flex-direction: row;
        width: 100%;
        height: auto;
        .sidebar-section-navlink {
            .nav-lnk {
                display: flex;
                list-style: none;
            li {

                & > a {
                    display: inline-flex;
                    padding: 23px 8%;
                    align-items: center;
                    height: 100%;

                    img {
                        display: block;
                        width: 24px;
                        height: 20px;
                        margin-left: auto;
                        margin-right: auto;
                        margin-top: 5px;
                    }

                    span {
                        display: block;
                        font-family: montserratmedium;
                        font-size: 14px;
                        line-height: 18px;
                        font-weight: normal;
                        font-stretch: normal;
                        font-style: normal;
                        clear: both;
                    }
                }
            }
        }
    }
        .sidebar-section-menu {
            height: auto;
            padding: 15px;
        }
    }
}

/*576px*/
@include layout(sm) {
    #sidebar-section{
        flex-direction: column;
        width: 100%;
        height: auto;
        .sidebar-section-navlink {
            .nav-lnk {
                display: flex;
                list-style: none;
                flex-direction: column;
            li {

                & > a {
                    display: flex;
                    padding: 20px 12% 20px 20px;
                    width: 100%;
                    align-items: center;

                    img {
                        display: flex;
                        width: 20px;
                        height: auto;
                        margin-left: 0px;
                        margin-right: 30px;
                        margin-top: 5px;
                    }

                    span {
                        display: block;
                        font-family: montserratmedium;
                        font-size: 14px;
                        line-height: 18px;
                        font-weight: normal;
                        font-stretch: normal;
                        font-style: normal;
                        clear: both;
                    }
                }
            }
        }
    }
        .sidebar-section-menu {
            height: auto;
            padding: 15px;
        }
    }
}

/*480px*/
@include layout(xs) {
    
}

/*320px*/
@include layout(xxs) {
}
