
  .templates-ul{
    visibility: hidden;
  }
  .templates-li:hover {
    background: #F1F1F1;
  }

  .rdw-block-custom-dropdown {
    width : 210px
  }