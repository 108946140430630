@import "../../styles/constants";
@import "../../styles/mixins";
.participants-help-form {
    position: absolute;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    width: 550px;
    .form-header {
        height: 18px;
        background-color: #16216a;
        border-top-left-radius: 15px;
        border-top-right-radius: 15px;
    }
    .form-content {
        padding: 35px;
        border-bottom-left-radius: 15px;
        border-bottom-right-radius: 15px;
        background-color: white;
        box-shadow: 0px 3px 6px #00000029;
    }

    .form-footer {
        margin-top: 22px;
        text-align: right;
    }
}

@include layout(xs) { 
    .participants-help-form {
        display: flex;
        flex-direction: column;
        width: 350px;
        margin-left: 40px;
        position: relative;
        top: 0%;
        left: 0%;
        transform: translate(0%)
    }
}
@media screen and (min-width: 322px) and (max-width: 400px) {
    .participants-help-form {
        width: 300px;
        margin-left: 20px;
    }
}
@include layout(xxs) { 
    .participants-help-form {
        width: 300px;
        margin-left: 5px;
    }
}


 @media screen and (orientation:landscape) and
(min-width: 200px) and (max-width: 1024px) and (max-height : 700px) {
    .participants-help-form {
        width: 550px;
        position: relative;
        top: 50%;
        left: 10%;
        transform: translate(0%);
        display: flex;
        flex-direction: column;
    }
}