@import "../../styles/constants";
@import "../../styles/mixins";
#participants-schedule-page {
    padding: 16px 38px 0 38px;

    .participants-schedule-header {
        padding: 16px 0;
    }

    .project-info {
        color: white;
        background-color: #16216a;
        border-radius: 10px;
        padding: 12px 18px;

        .label {
            font-weight: bolder;
        }
    }

    .project-description {
        color: #646d82;
        margin: 15px 0;

        .label {
            font-weight: bolder;
            color: #16216a;
        }
        .label-btn{
            display: flex;
            justify-content: flex-end;
            align-items: center;
        }
    }

    .participants-schedule-content {
        border: 1px solid #cecece;
        border-radius: 15px;
        background-color: white;

        .participants-schedule-navigator {
            position: relative;
            padding: 15px;

            .ant-pagination {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);

                .ant-pagination-disabled {
                    .navigator-prev,
                    .navigator-next {
                        color: #eeeeee;
                    }
                }

                .navigator-prev,
                .navigator-next {
                    font-size: 33px;
                    color: #a4afb7;
                }

                .ant-pagination-item {
                    min-width: 0;
                    margin: 0;
                    border: none;

                    .navigator-item {
                        padding: 5px 60px;
                        color: #16216a;
                        display: flex;

                        &.hide {
                            display: none;
                        }

                        span {
                            font-weight: bolder;
                        }

                        a {
                            color: #16216a;
                        }

                        .ant-pagination-item a {
                            display: inline-block;
                            padding: 0;
                        }
                    }
                }
            }
        }

        .participants-schedule-table-controls {
            text-align: right;
        }

        .participants-schedule-table-controls {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: space-between;
            align-items: center;
            align-content: center;
            padding: 16px 20px;

            .participants-schedule-table-controls-left {
               display: flex;
            }

            .participants-schedule-table-controls-right {
                display: flex;

                .table-controls-row {
                    display: flex;
                    margin: 5px 0;
                    align-items: center;
                    justify-content: flex-end;

                    .label {
                        margin-right: 5px;
                        font-size: 14px;
                    }
                }
            }
        }

        .participants-schedule-table {
            .ant-table-tbody > tr.ant-table-row:hover > td {
                font-weight: bold;
                background-color: #aedeef;
                color: #000000;
            }
        }
    }

    .colorBtnBooking {
        background-color: #359478;
        margin-top: 20px;
        color: white;
    }
}

@include layout(sm) {
    #participants-schedule-page{
        .project-description {
             display: flex;
             align-items: flex-start;
             justify-content: flex-start;
             flex-direction: column;
             padding-left: 10px;
             .label{
                 padding-top: 10px;
             }
        }
    }
}

@include layout(xs) {
    #participants-schedule-page{
        padding: 16px 10px 0 10px;
        .participants-schedule-content {
            margin-bottom: 20px;
        }
    }
}

@media screen and (orientation:landscape) and
(min-width: 200px) and (max-width: 1024px) and (max-height : 769px) {
    #participants-schedule-page{
       .participants-schedule-content{
             margin-bottom: 30px;
       }
    }
}
